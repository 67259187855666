/* eslint-disable prettier/prettier */
import React from "react";

import { cardStyle } from "../../styles";
import case1 from "../../images/case_1.png";
import case2 from "../../images/case_2.png";
import tick1 from "../../images/tick_1.png";
import tick2 from "../../images/tick_2.png";


const FlowChartMobile = () => {
    return (
        <div className="relative mb-20 block md:hidden">
            <div
                className="bg-blend-multiply rounded-lg w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 p-5"
                style={cardStyle}
            >
                <div className="w-full flex justify-end">
                    <img src={case1} className="h-14" />
                </div>
                <h2 className="text-lg font-black pt-5 text-right">
                    Case 1 <span className="text-grey-200 opacity-50">| User A</span>
                </h2>
                <p className="font-thin text-base text-right">
                    On maturity date User A pays back principal amount + interest and
                    NFT ownership is transferred back
                </p>
            </div>
            <div className="w-100 flex h-full justify-center relative p-2">
                <div className="w-1 h-40 bg-green-600 bg-opacity-40" />
                <div className="absolute top-1/3 -left-0 w-3/5 flex">
                    <div className="font-thin text-base text-left">
                        Own NFT & wants to borrow against it
                    </div>
                    <img src={tick1} className="h-14 float-right" />
                </div>
            </div>
            <div
                className="bg-blend-multiply rounded-lg w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 p-5"
                style={cardStyle}
            >
                <h2 className="text-center font-black text-2xl mb-2">
                    Strip Marketplace
                </h2>
                <p className="font-thin text-base text-center">
                    Option to upload NFT details/fetch from marketplaces like{" "}
                    <span className="font-semibold">Opensea, Rarible, Binance </span>etc
                </p>
            </div>
            <div className="w-100 flex h-full justify-center relative p-2">
                <div className="w-1 h-40 bg-green-600 bg-opacity-40" />
                <div className="absolute top-6 left-44 w-2/4 flex justify-center items-center">
                    <img src={tick2} className="h-14 float-right" />
                    <div className="font-thin text-base text-left">
                        Bids for the NFT mentoring the amount plus interest rate and time
                        duration
                    </div>

                </div>
            </div>
            <div
                className="bg-blend-multiply rounded-lg w-full sm:w-2/6 md:w-2/6 lg:w-2/6 xl:w-2/6 2xl:w-2/6 p-5 mb-5"
                style={cardStyle}
            >
                <img src={case2} className="h-14" />
                <h2 className="text-lg font-black pt-5">
                    Case 2 <span className="opacity-50">| User B</span>
                </h2>
                <p className="font-thin text-base">
                    If User A is unable to pack back the predetermined amount,the NFT
                    ownership in this case is transferred to User B (the lender)
                </p>
            </div>
        </div>
    );
};

export default FlowChartMobile;
