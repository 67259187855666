import * as React from "react";

import Layout from "../components/layouts";
import Pager from "../components/layouts/Pager";
import FlowChartDesktop from "../components/how-it-works/FlowChartDesktop";
import Heading from "../components/how-it-works/Heading";
import FlowChartMobile from "../components/how-it-works/FlowChartMobile";
import backGround from "../images/crystal_roadmap.png";

// markup
const IndexPage = () => {
  return (
    <Layout hideTeam>
      <div
        className="pb-60"
        style={{
          background: `url(${backGround})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Pager>
          <Heading />
          <FlowChartDesktop />
          <FlowChartMobile />
        </Pager>
      </div>
    </Layout>
  );
};

export default IndexPage;
