import React from "react";

const Heading = () => {
  return (
    <div className="flex justify-center items-center md:p-20 py-10 relative z-10">
      <div>
        <h1 className="font-black md:text-7xl text-3xl text-center">
          How it Works
        </h1>
        <br />
        <div className="text-center">
          Enables of Liquidity in NFT & DeFi Landscape
        </div>
      </div>
    </div>
  );
};

export default Heading;
