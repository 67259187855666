import React from "react";

import { cardStyle } from "../../styles";
import case1 from "../../images/case_1.png";
import case2 from "../../images/case_2.png";
import tick1 from "../../images/tick_1.png";
import tick2 from "../../images/tick_2.png";

const FlowChartDesktop = () => {
  return (
    <div className="relative mb-20 hidden md:block">
      <div className="w-full flex justify-center">
        <div className="w-1/4 pt-20 pb-4 pr-3 relative">
          <div className="left-arrow-top" />
          <div className="left-arrow-left" />
          <div className="absolute absolute-icons right-28">
            <div className="font-thin text-base md:text-sm text-left">
              Own NFT & wants to borrow against it
            </div>
            <img src={tick1} className="h-14 float-right" />
          </div>
        </div>

        <div
          className="bg-blend-multiply rounded-lg w-2/6 p-7 mb-16"
          style={cardStyle}
        >
          <h2 className="text-center font-black text-3xl mb-2">
            Strip Marketplace
          </h2>
          <p className="font-thin text-base text-center">
            Option to upload NFT details/fetch from marketplaces like{" "}
            <span className="font-semibold">Opensea, Rarible, Binance </span>etc
          </p>
        </div>
        <div className="w-1/4 pt-20 pb-4 pl-3">
          <div className="right-arrow-top" />
          <div className="right-arrow-right" />
          <div className="absolute absolute-icons w-1/4 -right-8">
            <div className="font-thin text-base md:text-sm text-left">
              Bids for the NFT mentoring the amount plus interest rate and time
              duration
            </div>
            <img src={tick2} className="h-14" />
          </div>
        </div>
      </div>
      <div className="z-10">
        <div className="flex flex-row justify-between items-start gap-x-10">
          <div
            className="bg-blend-multiply rounded-lg w-2/6 p-5"
            style={cardStyle}
          >
            <div className="w-full flex justify-end">
              <img src={case1} className="h-14" />
            </div>
            <h2 className="text-lg font-black pt-5 text-right">
              Case 1 <span className="text-grey-200 opacity-50">| User A</span>
            </h2>
            <p className="font-thin text-base text-right">
              On maturity date User A pays back principal amount + interest and
              NFT ownership is transferred back
            </p>
          </div>

          <div
            className="bg-blend-multiply rounded-lg w-2/6 p-5"
            style={cardStyle}
          >
            <img src={case2} className="h-14" />
            <h2 className="text-lg font-black pt-5">
              Case 2 <span className="opacity-50">| User B</span>
            </h2>
            <p className="font-thin text-base">
              If User A is unable to pack back the predetermined amount,the NFT
              ownership in this case is transferred to User B (the lender)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowChartDesktop;
